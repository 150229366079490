import { addDays, format, getTime } from 'date-fns';
import React from 'react';
import { useState } from 'react';
import { ErrorComponent } from './ErrorComponent';
import { DayPicker } from 'react-day-picker';
import { useMediaQuery } from 'react-responsive'
import { de } from 'date-fns/locale';
import 'react-day-picker/dist/style.css'; // for day picker styles

export const QuickSearchComponent = () => {
    const currentDate = new Date();
    const minDateFrom = addDays(currentDate, 1);
    const minDateTo = addDays(currentDate, 3);
    const maxDate = addDays(currentDate, 365);
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const isLandscape = useMediaQuery({ query: '(orientation: landscape)' });
    const [isLoading, setIsLoading] = useState(false);

    // date picker initial values
    const defaultSelected = {
        from: minDateFrom,
        to: minDateTo,
    };
    const [selectedRange, setSelectedRange] = useState(defaultSelected);
    const [people, setPeople] = useState(1);
    const [error, setError] = useState('');

    const dateRangeChangeHandler = (range) => {
        setSelectedRange(range);
    };
    const personsChangeHandler = (e) => {
        const persons = e.target.value;

        if (persons < 1 || persons > 6) {
            setError('Ungültige Anzahl an Personen.');
        }

        setPeople((prev) => ({...prev, persons}));
        setError(() => '');
    };

    const quickSearchHandler = async (e) => {
        e.preventDefault();

        if (error !== '') {
            return;
        }

        try {
            setIsLoading(() => true);

            if (!selectedRange) {
                setIsLoading(() => false);
                setError(() => 'Bitte wählen Sie einen gültigen Zeitraum aus.');
                return;
            }

            if (!selectedRange.from && !selectedRange.to) {
                setIsLoading(() => false);
                setError(() => 'Bitte wählen Sie einen gültigen Zeitraum aus.');
                return;
            }

            const startDate = selectedRange.from;
            const endDate = selectedRange.to;
            const persons = people;
            const days = (getTime(endDate) - getTime(startDate)) / (1000 * 60 * 60 * 24);

            if (
                startDate > endDate ||
                endDate < startDate ||
                startDate > maxDate ||
                endDate > maxDate ||
                getTime(startDate) === getTime(endDate)
            ) {
                setIsLoading(() => false);
                setError(() => 'Bitte wählen Sie einen gültigen Zeitraum aus.');
                return;
            }

            if (days >= 30) {
                setIsLoading(() => false);
                setError(() => 'Für Langzeitaufenthalte kontaktieren Sie uns bitte telefonisch oder per E-Mail.');
                return;
            }

            // redirect to /ferienwohnungen/buchen page with all the search parameters
            location.href = `/ferienwohnungen/buchen?numAdults=${persons}&adultOnly=1&numChildren=0&childrenDOBs=nochild&cid=10243&lid=2&arrivalDate=${
                format(startDate, 'yyyy-MM-dd')
            }&departureDate=${format(endDate, 'yyyy-MM-dd')}`;
        } catch (err) {
            console.log(err);
            setError(() => 'error');
            setIsLoading(() => false);
        }
    };

    if (error === 'error') {
        return <ErrorComponent showBackground={true}/>;
    }

    return (
        <div className="container">
            <div className="row bg-light m-1 rounded border border-primary justify-content-center shadow">
                <p className="text-primary text-center text-md-start">
                    <strong>Jetzt Verfügbarkeit prüfen</strong>
                    <span className="fw-lighter"> und gleich online buchen</span>
                </p>
                <div className="col-sm-12 col-md-12">

                    {
                        ((isMobile || isLandscape) && !isDesktopOrLaptop) ?
                            <DayPicker
                                numberOfMonths={1}
                                locale={de}
                                mode={'range'}
                                className="form-control border-primary text-primary"
                                defaultMonth={currentDate}
                                fromDate={currentDate}
                                toDate={maxDate}
                                selected={selectedRange}
                                onSelect={dateRangeChangeHandler}
                            />
                            :
                            <DayPicker
                                numberOfMonths={3}
                                locale={de}
                                mode={'range'}
                                className="form-control border-primary text-primary"
                                defaultMonth={currentDate}
                                fromDate={currentDate}
                                toDate={maxDate}
                                selected={selectedRange}
                                onSelect={dateRangeChangeHandler}
                            />
                    }

                    <div className="form-floating mt-3">
                        <select
                            className="form-select border-primary text-primary"
                            name="persons"
                            id="fwswp-searchPersons"
                            aria-label="Anzahl Personen"
                            required
                            value={people}
                            onChange={personsChangeHandler}
                        >
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                        </select>
                        <label htmlFor="fwswp-searchPersons">Anzahl Personen</label>
                    </div>
                    <div className="form-floating mt-3">
                        <input
                            id="fwswp-searchBtn"
                            type="button"
                            value="Suchen"
                            className="btn btn-primary w-100"
                            onClick={quickSearchHandler}
                        />
                    </div>
                </div>

                <div id="fwswp-searchValidationContainer" className={`row ${!error ? "visually-hidden" : ""}`}>
                    <div className="col-md-12 col-xs-12">
                        <div id="fwswp-searchValidation" className="text-center text-danger">
                            {error}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
