import React from 'react';

export const ErrorComponent = ({ showBackground }) => {
    return (
        <div className="container">
            <div
                className={`row m-1 rounded border border-primary justify-content-center text-center ${
                    showBackground ? "bg-light" : ""
                }`}
            >
                <div className="row">
                    <div className="col-sm-12 col-xs-12">
                        <i className="bi bi-emoji-dizzy text-primary" style={{ fontSize: "200px" }} />
                        <p className="h3 text-primary">Ooops..</p>
                        <p>
                            Leider konnte Ihre Anfrage nicht verarbeitet werden.
                            <br />
                            Bitte versuchen Sie es in wenigen Minuten noch einmal.
                            <br />
                            Sollte das Probleme weiterhin bestehen, kontaktieren Sie uns gerne per E-Mail oder
                            telefonisch:
                            <br />
                            <a href="tel:+4963389948231">
                                <i className="bi bi-telephone" /> +49 (0) 6338 9948231
                            </a>
                            <br />
                            <a href="mailto:buchung@ferienwohnungen-suedwestpfalz.de">
                                <i className="bi bi-envelope" /> buchung@ferienwohnungen-suedwestpfalz.de
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};
